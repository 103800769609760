import React, { useEffect, useState } from "react";
import { FiUserPlus, FiUsers, FiEdit, FiEye } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { AiFillStar } from "react-icons/ai";
import { toast } from "react-toastify";
import { TbRefresh } from "react-icons/tb";
import DriverDetailsModal from "./Modal/DriverDetailsModal";
import UpdateDriverModal from "./Modal/UpdateDriverModal";
import axios from "../axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";

const DriverInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setDetailsModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const inputWidth = screenWidth > 425 ? "250px" : "1px";

  const fetchApiData = () => {
    axios.get("/user/getDrivers").then((res) => {
      setData(
        res?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    });
  };

  useEffect(() => {
    fetchApiData();
  }, [showModal]);

  const getLatestJoining = () => {
    return data?.filter(
      (driver) => moment(driver.createdAt).month === moment().month
    ).length;
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const getSearch = () => {
    const result = data.filter(
      (driver) =>
        driver?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        driver?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        driver?.phone?.includes(searchValue)
    );
    return result;
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/deleteUser/${id}`)
          .then((res) => {
            toast.success(res.data.message);
            fetchApiData();
          })
          .catch((err) => {
            toast.error(err.res.data.message);
          });
      }
    });
  };

  // const deleteUsser = (id) => {
  //   const confirmed = window.confirm("Are You Sure?");
  //   if (confirmed) {
  //     axios
  //       .delete(`/user/deleteUser/${id}`)
  //       .then((res) => {
  //         toast.success(res.data.message);
  //         fetchApiData();
  //       })
  //       .catch((err) => {
  //         toast.error(err.res.data.message);
  //       });
  //   }
  // };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(getSearch()?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data, searchValue]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  function handleClear() {
    setSearchValue("");
  }

  return (
    <>
      <div className="pcoded-main-container">
        {showModal && (
          <UpdateDriverModal
            show={showModal}
            driver={data[selectedDriver]}
            onHide={() => setShowModal(false)}
          />
        )}
        {showDetailModal && (
          <DriverDetailsModal
            driver={data[selectedDriver]}
            show={showDetailModal}
            onHide={() => setDetailsModal(false)}
          />
        )}

        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card daily-sales">
                        <div className="card-block">
                          <h5 className="mb-4">
                            <FiUserPlus className="mr-2" />
                            Drivers Joined This Month
                          </h5>
                          <div className="row d-flex align-items-center">
                            <div className="col-9">
                              <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                {getLatestJoining()}
                              </h3>
                            </div>
                          </div>
                          <div
                            className="progress m-t-30"
                            style={{ height: "7px" }}
                          >
                            <div
                              className="progress-bar progress-c-theme"
                              role="progressbar"
                              style={{ width: "100%" }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card daily-sales">
                        <div className="card-block">
                          <h5 className="mb-4">
                            <FiUsers className="mr-2" />
                            Total Drivers
                          </h5>
                          <div className="row d-flex align-items-center">
                            <div className="col-9">
                              <h3 className="f-w-300 d-flex align-items-center m-b-0">
                                <i className="feather icon-arrow-up text-c-green f-30 m-r-10"></i>
                                {data?.length}
                              </h3>
                            </div>
                          </div>
                          <div
                            className="progress m-t-30"
                            style={{ height: "7px" }}
                          >
                            <div
                              className="progress-bar progress-c-theme"
                              role="progressbar"
                              style={{ width: "100%" }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header d-flex justify-content-between bg-light">
                        <h5 className="mt-2">Drivers</h5>
                        <div
                          className="pcoded-header justify-content-between ml-0"
                          style={{
                            marginBottom: "-30px",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span
                            className="col-lg-9"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <ul>
                              <li className="nav-item ">
                                <div className="main-search open d-flex align-items-center">
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      id="m-search"
                                      className="form-control _search"
                                      placeholder="Search..."
                                      style={{ width: inputWidth }}
                                      onChange={handleChange}
                                      value={searchValue}
                                    />
                                    <i
                                      className="feather icon-x input-group-text cursor-pointer"
                                      onClick={handleClear}
                                    ></i>
                                    <i className="feather icon-search input-group-text p-2 cursor-pointer"></i>
                                  </div>
                                  <TbRefresh
                                    size={25}
                                    className="ml-3 cursor-pointer"
                                    onClick={fetchApiData}
                                  />
                                </div>
                              </li>
                            </ul>
                          </span>
                        </div>
                      </div>
                      <div className="card-block table-border-style">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Contact</th>
                                <th>Email</th>
                                <th>Ratings</th>
                                <th>Status</th>
                                <th>Account</th>
                                <th>Action</th>
                                <th>View</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems?.map((driver, i) => (
                                <tr key={driver?._id}>
                                  <th scope="row">{i + 1}</th>
                                  <td>{driver?.firstName}</td>
                                  <td>{driver?.phone}</td>
                                  <td>{driver?.email}</td>
                                  <td className="d-flex align-items-center">
                                    {driver?.rating}{" "}
                                    <AiFillStar className="ml-2 text-warning" />{" "}
                                  </td>
                                  <td>
                                    <span className="d-flex align-items-center">
                                      {driver.isOnline ? (
                                        <>
                                          <TiTick
                                            size={18}
                                            className="mr-1 text-success"
                                          />
                                          Online
                                        </>
                                      ) : (
                                        <>
                                          <MdCancel
                                            size={18}
                                            className="mr-1 text-danger"
                                          />
                                          Offline
                                        </>
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {driver.isAccountVerified
                                      ? "Verified"
                                      : "Unverified"}
                                  </td>
                                  <td>
                                    <FiEdit
                                      className="ml-3 c_pointer"
                                      onClick={() => {
                                        setSelectedDriver(itemOffset + i);
                                        setShowModal(true);
                                      }}
                                      size={18}
                                    />
                                  </td>
                                  <td>
                                    <FiEye
                                      onClick={() => {
                                        setSelectedDriver(itemOffset + i);
                                        setDetailsModal(true);
                                      }}
                                      size={19}
                                      className="ml-2 c_pointer"
                                    />
                                  </td>
                                  <td onClick={() => deleteUser(driver?._id)}>
                                    <AiFillDelete
                                      size={20}
                                      className="ml-2 c_pointer text-danger"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default DriverInfo;
