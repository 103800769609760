import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "../axios";
const ChartLine = () => {
  const [userList, setUserList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [driverList, setDriverList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const sortUsers = async () => {
    const res = await axios.get("/user/getUsers");
    const users = res.data;
    let usersL = [...userList];

    for (var i = 0; i < users.length; i++) {
      const month = moment(users[i].createdAt).month();
      usersL = usersL?.map((num, index) => (index === month ? num + 1 : num));
    }
    setUserList(usersL);
  };

  const sortDrivers = async () => {
    const res = await axios.get("/user/getDrivers");
    const drivers = res.data;
    let driversL = [...driverList];
    for (var i = 0; i < drivers.length; i++) {
      const month = moment(drivers[i].createdAt).month();
      driversL = driversL?.map((num, index) =>
        index === month ? num + 1 : num
      );
    }
    setDriverList(driversL);
  };

  useEffect(() => {
    sortUsers();
  }, []);
  useEffect(() => {
    sortDrivers();
  }, []);
  // useEffect(() => {}, []);
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    stroke: {
      curve: "smooth",
    },
  };
  const series = [
    {
      name: "Drivers",
      data: driverList,
    },
    {
      name: "Users",
      data: userList,
    },
  ];
  return (
    <div className="row">
      <div className="mixed-chart">
        <Chart options={options} series={series} type="area" width="700px" />
      </div>
    </div>
  );
};

export default ChartLine;
