import React from "react";
import applogo from "../../src/assets/images/applogo.png";
import tickicon from "../../src/assets/images/tickk.png";

const SuccessPage = () => {
  return (
    <div className="container mt-5">
      <div className="d-flex align-items-center justify-content-center">
        <img src={applogo} alt="Logo" />
      </div>
      <div className="mt-5">
        <p className="text-center display-4 mt-2">
          Account Created Successfully!
        </p>
      </div>
      <div className="text-center">
        <img src={tickicon} alt="success" className="h-25 w-25" />
      </div>
    </div>
  );
};

export default SuccessPage;
