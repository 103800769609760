import React, { useEffect, useState } from "react";
import ChartLine from "../charts/ChartLine";
import { RiRadioButtonLine } from "react-icons/ri";
import { TbSteeringWheel } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import axios from "../axios";
import { AiOutlineNumber } from "react-icons/ai";
const AdminDashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get("/user/getAdminData").then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-md-6 col-xl-4">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="mb-4">Daily Bookings</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.dailyBookings} </b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="card Monthly-sales">
                      <div className="card-block">
                        <h5 className="mb-4">Weekly Bookings</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.weeklyBookings} </b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme2"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="35"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-4">
                    <div className="card Monthly-sales">
                      <div className="card-block">
                        <h5 className="mb-4">Monthly Bookings</h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex align-items-center">
                            <AiOutlineNumber
                              size={30}
                              className="text-gray mr-2 align-items-center"
                            />
                            <h3 className="f-w-300 align-items-center">
                              <b> {data?.monthlyBookings} </b>
                            </h3>
                          </div>
                        </div>
                        <div
                          className="progress m-t-30"
                          style={{ height: "7px" }}
                        >
                          <div
                            className="progress-bar progress-c-theme"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <h5>Chart</h5>

                    <ChartLine />
                  </div>

                  <div className="col-xl-4 col-md-6">
                    <div className="card card-event">
                      <div className="card-block">
                        <div className="row align-items-center justify-content-center">
                          <div className="col">
                            <h5 className="m-0">TOTAL BOOKINGS</h5>
                          </div>
                          {/* <div className="col-auto">
                            <label className="label theme-bg2 text-white f-14 f-w-400 float-right"></label>
                          </div> */}
                        </div>
                        <div className="d-flex">
                          <AiOutlineNumber
                            size={40}
                            className="text-gray mr-2 align-items-center mt-4"
                          />
                          <h2 className="mt-3 f-w-300">
                            {data?.totalBookings}
                          </h2>
                        </div>
                        <i className="fab fa-angellist text-c-purple f-50"></i>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-block border-bottom">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto">
                            <i>
                              <RiRadioButtonLine size={40} />
                            </i>
                          </div>
                          <div className="col">
                            <h3 className="f-w-300 d-flex">
                              {data?.drivers}
                              <span>
                                <TbSteeringWheel
                                  size={20}
                                  className="ml-2 text-secondary"
                                />
                              </span>
                            </h3>

                            <span className="d-block text-uppercase d-flex">
                              TOTAL ONLINE DRIVERS
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-block">
                        <div className="row d-flex align-items-center">
                          <div className="col-auto">
                            <i>
                              {" "}
                              <RiRadioButtonLine size={40} />
                            </i>
                          </div>
                          <div className="col">
                            <h3 className="f-w-300 d-flex">
                              {data?.users}
                              <span>
                                <FiUsers
                                  size={20}
                                  className="ml-2 text-secondary"
                                />
                              </span>
                            </h3>

                            <span className="d-block text-uppercase d-flex">
                              TOTAL ONLINE USERS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
