import React, { useState, useEffect } from "react";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { GiProfit } from "react-icons/gi";
import axios from "../axios";
import Cookies from "universal-cookie";
import {
  MdAirportShuttle,
  MdDeleteForever,
  MdLocalAirport,
  MdPriceChange,
} from "react-icons/md";
import { toast } from "react-toastify";
import profit_icon from "../assets/images/profit-icon.png";

const AdminProfile = () => {
  const [data, setData] = useState([]);
  const [percentage, setPercentage] = useState("");
  const [percentageValue, setPercentageValue] = useState(0);
  const [terminal, setTerminal] = useState("");
  const [terminalList, setTerminalList] = useState("");
  const [showTerminalList, SetShowTerminalList] = useState(false);
  const [normalRate, setNormalRate] = useState({
    pricePerKM: 0,
    pricePerMin: 0,
    priceExtra: 0,
  });
  const [airportRate, setAirportRate] = useState({
    pricePerKM: 0,
    pricePerMin: 0,
    priceExtra: 0,
  });
  const [bookingRates, setBookingRates] = useState([]);

  const [airportLocation, setAirportLocation] = useState({
    lat: "",
    long: "",
    name: "",
  });

  useEffect(() => {
    axios.get("/stripe/getAdminBalance").then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/user/margin").then((res) => {
      setPercentageValue(res.data.margin);
    });
    axios.get("/driver/getBookingRate").then((res) => {
      setBookingRates(res.data.bookingRate);
      const currentAirportRate = res.data.bookingRate.filter(
        (bookingRate) => bookingRate.type === "airport"
      );
      const currentNormalRate = res.data.bookingRate.filter(
        (bookingRate) => bookingRate.type === "normal"
      );
      setAirportRate({
        priceExtra: currentAirportRate[0].priceExtra,
        pricePerKM: currentAirportRate[0].pricePerKM,
        pricePerMin: currentAirportRate[0].pricePerMin,
      });
      setNormalRate({
        priceExtra: currentNormalRate[0].priceExtra,
        pricePerKM: currentNormalRate[0].pricePerKM,
        pricePerMin: currentNormalRate[0].pricePerMin,
      });
    });
  }, []);

  const setMargin = () => {
    axios
      .post("/user/margin", { margin: percentage })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const setBookingRate = (event, body) => {
    event.preventDefault();
    axios
      .post("/driver/addBookingRate", { ...body })
      .then((res) => {
        axios.get("/driver/getBookingRate").then((res) => {
          setBookingRates(res.data.bookingRate);
        });
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const [places, setPlaces] = useState([]);

  const onChange = (e) => {
    const value = e.target.value;
    setTerminal(value);
    const map = new window.google.maps.Map(document.getElementById("map"));
    const service = new window.google.maps.places.PlacesService(map);

    const request = {
      query: value,
      fields: ["name", "geometry"],
    };

    service.textSearch(request, (results, status) => {
      if (status === "OK") {
        setPlaces(results);
      }
    });
  };

  const getLocations = (place) => {
    const lat = place.geometry.location.lat();
    const long = place.geometry.location.lng();
    const name = place.name;
    setAirportLocation({ lat, long, name });
    setTerminal(name);
    setPlaces([]);
  };

  const setLocations = () => {
    axios
      .post("/user/location", airportLocation)
      .then((res) => {
        toast.success(res.data.message);
        setTerminal("");
        showTerminal();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    showTerminal();
  }, []);

  const showTerminal = () => {
    axios.get("/user/location").then((res) => {
      setTerminalList(res.data.locations);
    });
  };

  const deleteTerminal = (id) => {
    axios
      .patch(`/user/location`, { id })
      .then((res) => {
        toast.success(res.data.message);
        showTerminal();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const cookie = new Cookies();
  const user = cookie.get("user");

  return (
    <div className="pcoded-main-container">
      <div id="map" className="d-none"></div>
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex">
                        <div className="col-lg-4">
                          <img
                            src={user.user.profile}
                            alt=""
                            className="admin-profle-img"
                          />
                        </div>
                        <div className="col-lg-8">
                          <h3 className="mb-4 ml-4">Account Details</h3>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-8 ml-5 mt-4">
                              <h5>
                                <b>Name: </b> {user?.user?.firstName}{" "}
                                {user?.user?.lastName}
                              </h5>

                              <h5 className="mt-3">
                                <b> Email: </b> {user?.user?.email}
                              </h5>
                              <h5 className="mt-3">
                                <b> Contact: </b> {user?.user?.phone}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 position-relative">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex align-items-center">
                        <div className="col-lg-12">
                          <h3 className="mb-4"> Add Airport </h3>

                          <input
                            className="form-control mt-2 add_terminal"
                            value={terminal}
                            placeholder="Airpot Name"
                            onChange={onChange}
                          />
                          {places?.length > 0 && (
                            <div className="mt-4 ml-4 position-absolute location-container">
                              {places.map((place) => (
                                <h4
                                  onClick={() => getLocations(place)}
                                  key={place.id}
                                  className="cursor-pointer location-list"
                                >
                                  <i> {place.name} </i>
                                </h4>
                              ))}
                            </div>
                          )}

                          <button
                            className="btn btn-primary mt-4 _customButton float-right"
                            onClick={setLocations}
                            disabled={!terminal}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    <div
                      className="card daily-sales"
                      style={{ backgroundColor: "#EFF2F5" }}
                    >
                      <div className="card-block d-lg-flex align-items-center">
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <h3>Airport List</h3>
                            <button
                              className="btn btn-primary "
                              onClick={() =>
                                SetShowTerminalList(!showTerminalList)
                              }
                            >
                              Show/Update List
                            </button>
                          </div>
                          {showTerminalList && (
                            <div className="card-block table-border-style">
                              <div className="table-responsive">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {terminalList?.map((item, i) => (
                                      <tr key={item?.id}>
                                        <th>{++i}</th>
                                        <td>{item?.name}</td>
                                        <td>
                                          <span
                                            onClick={() =>
                                              deleteTerminal(item.id)
                                            }
                                            className="cursor-pointer"
                                          >
                                            <MdDeleteForever size={22} />
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="d-flex">
                          <RiMoneyDollarCircleLine className="mr-2" size={20} />
                          Avaliable Balance
                        </h5>
                        <h5 className="mt-3 ml-4">
                          $ {data?.balance?.available?.amount / 100}
                        </h5>
                        {/* <h6 className=" ">Current: 12%</h6> */}
                        <div className="row d-flex align-items-center">
                          <div className="col-9 d-flex ml-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="d-flex">
                          <RiMoneyDollarCircleLine className="mr-2" size={20} />
                          Pending Amount
                        </h5>
                        <h5 className="mt-3 ml-4">
                          $ {data?.balance?.pending?.amount / 100}
                        </h5>
                        {/* <h6 className=" ">Current: 12%</h6> */}
                        <div className="row d-flex">
                          <div className="col-9 d-flex ml-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="d-flex">Set Normal Booking Rates</h5>

                        <div className="row d-flex align-items-center">
                          <form
                            onSubmit={(e) =>
                              setBookingRate(e, {
                                type: "normal",
                                ...normalRate,
                              })
                            }
                            className="col-12 d-flex flex-column "
                          >
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Normal rate per KM"
                              value={normalRate.pricePerKM}
                              // min={0}
                              onChange={(e) =>
                                setNormalRate({
                                  ...normalRate,
                                  pricePerKM: e.target.value,
                                })
                              }
                            />
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Normal rate per Min"
                              value={normalRate.pricePerMin}
                              // min={0}
                              onChange={(e) =>
                                setNormalRate({
                                  ...normalRate,
                                  pricePerMin: e.target.value,
                                })
                              }
                            />
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Normal rate Extra"
                              value={normalRate.priceExtra}
                              // min={0}
                              onChange={(e) =>
                                setNormalRate({
                                  ...normalRate,
                                  priceExtra: e.target.value,
                                })
                              }
                            />
                            <button
                              type="submit"
                              className="btn btn-primary _customButton mt-2 mb-2 mr-0"
                              // disabled={airportRate == 0}
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <div className="row align-items-center justify-content-center">
                          <div className="col">
                            <h5 className="m-0">
                              Current Normal Booking Rates
                            </h5>
                          </div>
                        </div>
                        <div className="row d-flex mt-4 ">
                          {bookingRates.length > 0 &&
                            bookingRates
                              .filter(
                                (bookingRate) => bookingRate.type === "normal"
                              )
                              .map((bookingRate) => (
                                <div
                                  key={bookingRate.type}
                                  className="col-12 d-flex flex-column"
                                >
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Price per kilometer: ${" "}
                                    {bookingRate.pricePerKM}{" "}
                                    <span className="text-lowercase">kmph</span>
                                  </h4>
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Price per minute: ${" "}
                                    {bookingRate.pricePerMin}{" "}
                                  </h4>
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Extra Price: $ {bookingRate.priceExtra}{" "}
                                  </h4>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="d-flex">Set Airport Booking Rates</h5>

                        <div className="row d-flex align-items-center">
                          <form
                            className="col-12 d-flex flex-column "
                            onSubmit={(e) =>
                              setBookingRate(e, {
                                type: "airport",
                                ...airportRate,
                              })
                            }
                          >
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Airport rate per KM"
                              value={airportRate.pricePerKM}
                              // min={0}
                              onChange={(e) =>
                                setAirportRate({
                                  ...airportRate,
                                  pricePerKM: e.target.value,
                                })
                              }
                            />
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Airport rate per Min"
                              value={airportRate.pricePerMin}
                              // min={0}
                              onChange={(e) =>
                                setAirportRate({
                                  ...airportRate,
                                  pricePerMin: e.target.value,
                                })
                              }
                            />
                            <input
                              className="rate-input mt-2"
                              type="number"
                              placeholder="Airport rate Extra"
                              value={airportRate.priceExtra}
                              // min={0}
                              onChange={(e) =>
                                setAirportRate({
                                  ...airportRate,
                                  priceExtra: e.target.value,
                                })
                              }
                            />
                            <button
                              type="submit"
                              className="btn btn-primary _customButton mt-2 mb-2 mr-0"
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <div className="row align-items-center justify-content-center">
                          <div className="col">
                            <h5 className="m-0">
                              Current Airport Booking Rates
                            </h5>
                          </div>
                        </div>
                        <div className="row d-flex mt-4 ">
                          {bookingRates.length > 0 &&
                            bookingRates
                              .filter(
                                (bookingRate) => bookingRate.type === "airport"
                              )
                              .map((bookingRate) => (
                                <div
                                  key={bookingRate.type}
                                  className="col-12 d-flex flex-column"
                                >
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Price per kilometer: ${" "}
                                    {bookingRate.pricePerKM}{" "}
                                    <span className="text-lowercase">kmph</span>
                                  </h4>
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Price per minute: ${" "}
                                    {bookingRate.pricePerMin}{" "}
                                  </h4>
                                  <h4 className=" f-w-200 mt-2 mb-3 text-capitalize">
                                    Extra Price: $ {bookingRate.priceExtra}{" "}
                                  </h4>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <h5 className="d-flex">Set Your Margin</h5>

                        <div className="row d-flex align-items-center">
                          <div className="col-12 d-flex justify-content-between">
                            <input
                              className="percentage-input mt-2"
                              type="number"
                              step="0.01"
                              min={0}
                              placeholder="Margin"
                              value={percentage}
                              onChange={(e) => setPercentage(e.target.value)}
                            />
                            <button
                              onClick={setMargin}
                              className="btn btn-primary _customButton mt-2 mb-2"
                              disabled={percentage == 0}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card daily-sales">
                      <div className="card-block">
                        <div className="row align-items-center justify-content-center">
                          <div className="col">
                            <h5 className="m-0">Current Margin</h5>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <GiProfit
                              size={25}
                              className="text-gray mr-2 align-items-center mt-4"
                            />
                            <h4 className="mt-3 f-w-300 mt-4 mb-3">
                              {percentageValue}%
                            </h4>
                          </div>
                          <img
                            className="profit-icon-img"
                            src={profit_icon}
                            alt="Icon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
